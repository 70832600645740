@font-face {
    font-family: 'Ford\'s Folly';
    src: url('../../dist/font/FordsFolly.woff2') format('woff2'),
        url('../../dist/font/FordsFolly.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ford\'s Folly';
    src: url('../../dist/font/FordsFolly-Bol.woff2') format('woff2'),
        url('../../dist/font/FordsFolly-Bol.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}