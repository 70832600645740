@import "variables";
@import "fonts";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

html {
	background: $black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: 'Ford\'s Folly';
	text-transform: uppercase;
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
}

h1,h2,h3,h4,p,ul{
	margin: 0;
	line-height: 1.4;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

// .thank-you{
// 	color: $yellow;
// 	background-color: $black;
// 	text-align: center;
// 	padding: 10px 0;
// 	font-size: 700;
// 	display: none;
// 	.container{
// 		position: relative;
// 		.thank-you-close{
// 			position: absolute;
// 			top: 0;
// 			right: 0;
// 			background-color: transparent;
// 			padding: 0;
// 			line-height: 1;
// 			border: none;
// 			color: $white;
// 			height: 100%;
// 			font-size: 30px;
// 			@include flex(column,center,center);
// 			@include hover-focus{
// 				color: $yellow;
// 			}
// 		}
// 	}
// }

.thank-you{
	.popup.spotify{
		display: block;
		.container{
			.wrapper{
				.form-alert{
					&.success{
						display: block;
					}
				}
			}
		}
	}
}

header {
	background-color: $yellow;
	padding: 10px 0;
	border-top: solid 9px $black;
	border-bottom: solid 9px $black;
	.container{
		h1{
			text-align: center;
			font-size: 35px;
			color: $black;
			font-weight: bold;
			span{
				display: block;
				&:nth-child(2){
					font-size: 23px;
				}
			}
		}
	}
	@include tablet-up{
		.container{
			h1{
				span{
					display: inline;
				}
			}
		}
	}
}

main {
	section{

		&.hero{
			img{
				width: 100%;
				height: auto;
				object-fit: cover;
			}
		}

		&.buttons{
			background-color: $yellow;
			.container{
				padding: 0;
				max-width: 100%;
				.wrapper{
					.notice{
						background-color: $black;
						text-align: center;
						padding: 10px 10px 0;
						font-family: Arial, Helvetica, sans-serif;
						font-size: 8px;
					}
					a{
						padding: 15px 10px;
						border-top: solid 9px $black;
						display: block;
						width: 100%;
						@include flex(row,center,center);
						gap: 10px;
						line-height: 1;
						font-size: 27px;
						color: $black;
						font-weight: bold;
						img{
							height: 40px;
						}
					}
					>a{
						font-size: 35px;
					}
					.float{
						a{
							&:last-child{
								span{
									transform: translateY(3px);
								}
							}
						}
					}
				}
			}
			@include tablet-up{
				.container{
					.wrapper{
						.float{
							position: absolute;
							bottom: 200px;
							@include flex(row,space-between,center);
							width: 100%;
							padding:  0 60px;
							a{
								display: block !important;
								border: none;
								color: transparent;
								background-size: contain;
								background-position: center;
								background-repeat: no-repeat;
								@include flex(column,center,center);
								width: 375px;
								height: 190px;
								span{
									pointer-events: none;
								}
								img{
									opacity: 0;
									pointer-events: none;
								}
								&:first-child{
									background-image: url('../../dist/img/btn-spotify.png');
								}
								&:last-child{
									background-image: url('../../dist/img/btn-apple.png');
								}
							}
						}
					}
				}
			}
		}
		
	}

	.popup{
		display: none;
		background-color: rgba(0, 0, 0, 0.9);
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		.container{
			height: 100vh;
			@include flex(column,center,center);
			.wrapper{
				background-color: $yellow;
				padding: 10px 10px 30px;
				width: 100%;
				max-width: 400px;
				.heading{
					@include flex(row,center,center);
					gap: 10px;
					font-weight: bold;
					font-size: 26px;
					color: $black;
					width: 100%;
					img{
						height: 20px;
					}
				}
				.close{
					background-color: $black;
					border: none;
					border-radius: 0;
					color: $white;
					padding: 0;
					width: 30px;
					height: 30px;
					line-height: 1;
					font-size: 20px;
					margin-left: auto;
					@include flex(row,center,center);
				}
				form{
					margin-top: 30px;
					position: relative;
					overflow: hidden;
					label{
						color: $black;
						margin-bottom: 10px;
						font-size: 16px;
						text-align: center;
						position: absolute;
						left: -99999999px;
						opacity: 0;
						pointer-events: none;
					}
					input{
						width: calc(100% - 42px);
						border: solid 2px transparent;
						border-bottom-color: $black;
						background-color: transparent;
						color: $black;
						font-size: 16px;
						text-transform: uppercase;
						font-weight: bold;
						transition: all .4s;
						&::placeholder{
							color: $black;
							text-transform: uppercase;
						}
					}
					button{
						width: 42px;
						background-color: transparent;
						border: solid 2px transparent;
						border-bottom-color: $black;
						text-transform: uppercase;
						font-size: 16px;
						font-weight: bold;
						position: absolute;
						top: 0;
						right: 0;
						height: 100%;
					}
				}
				.form-alert{
					color: $black;
					display: none;
					font-size: 14px;
					text-align: center;
					&.error{
						margin-top: 10px;
					}
				}
			}
		}
	}
}

footer {
	background-color: $yellow;
	border-top: solid 9px $black;
	border-bottom: solid 9px $black;
	padding: 20px 0;
	.container{
		.social{
			@include flex(row,center,center);
			gap: 20px;
			margin-bottom: 20px;
			a{
				font-size: 20px;
				color: $black;
			}
		}
		.newsletter{
			margin-bottom: 20px;
			.hidden{
				display: none;
			}
			form{
				overflow: hidden;
				label{
					color: $black;
					margin-bottom: 10px;
					font-size: 16px;
					text-align: center;
					position: absolute;
					left: -99999999px;
					opacity: 0;
					pointer-events: none;
				}
				.form-row{
					position: relative;
					max-width: 260px;
					margin: 0 auto;
					border-bottom: solid 2px $black;
	
					input{
						text-transform: uppercase;
						width: calc(100% - 44px);
						background-color: transparent;
						border: none;
						color: $black;
						font-size: 16px;
						font-weight: bold;
						padding-top: 5px;
						padding-bottom: 5px;
						&::placeholder{
							color: $black;
						}
					}
					button{
						background-color: transparent;
						text-transform: uppercase;
						border: none;
						width: 44px;
						font-size: 16px;
						font-weight: bold;
						position: absolute;
						top: 0;
						right: 0;
						height: 100%;
					}
				}
			}
			.form-alert{
				text-align: center;
				color: $black;
				display: none;
				&.error{
					margin-top: 5px;
				}
			}
		}
		.copyright{
			text-align: center;
			img{
				display: inline-block;
				height: 12px;
				margin-bottom: 10px;
			}
			p{
				font-family: Arial, Helvetica, sans-serif;
				text-transform: unset;
				color: $black;
				font-size: 8px;
				text-transform: unset;
				a{
					color: $black;
				}
			}
		}
	}
	@include tablet-up{
		padding: 0;
		.container{
			max-width: 100%;
			@include flex(row,space-between,initial);
			div{
				padding: 10px;
				flex:  1;
			}
			.social{
				order: 1;
				margin: 0;
			}
			.newsletter{
				order: 3;
				margin-bottom: 0;
				form{
					.form-row{
						div{
							padding: 0;
						}
						input{
							font-size: 18px;
							padding: 0;
							&::placeholder{
								font-size: 18px;
							}
						}
					}
				}
			}
			.copyright{
				order: 2;
				flex: 2;
				border-left: solid 9px $black;
				border-right: solid 9px $black;
			}
		}
	}
}